/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    strong: "strong",
    br: "br",
    ol: "ol",
    li: "li",
    ul: "ul"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      children: "MY CREDIT HISTORY"
    }), "\n", _jsx(_components.p, {
      children: "The Credit Information Bureau has created a service which allows private individuals to see what information about them is stored in the system operated by the Credit Information Bureau or in other, external registers. The external registers and companies which data is stored in the system operated by the Credit Information Bureau are listed in section “Sources of credit information“."
    }), "\n", _jsxs(_components.p, {
      children: ["The Credit Information Bureau provides each client with a free credit history report twice a year via website ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), " or personally at Grēdu iela 4a in Riga. If you wish to check your credit history more than twice a year, you must purchase the annual subscription. The annual subscription fee is 17 EUR (incl. VAT). The annual subscription provides unlimited access to your data for the next 365 days."]
    }), "\n", _jsxs(_components.p, {
      children: ["To receive a credit history report in person, please have a valid passport or a valid ID card (the eID) with you. If you can not check your information at ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), " or receive the service in person at Grēdu iela 4a in Riga the private individual may, pursuant to Part 2 of Article 19 of the Law on Credit Information Bureaus, receive the information by post, covering the expenses incurred by the Credit Information Bureau for the postal services (according to the rates fixed by the postal service provider)."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "In case the provided data is false or inaccurate, please contact us and inform the responsible body about the problem."
      })
    }), "\n", _jsx(_components.h2, {
      children: "HISTORY OF CREDIT REPORT ENQUIRIES"
    }), "\n", _jsx(_components.p, {
      children: "Respecting the interests of private individuals, the Credit Information Bureau has set up a data exchange system which logs every enquiry for each private individual."
    }), "\n", _jsx(_components.p, {
      children: "This allows to monitor the legality and validity of data use, as you are given an opportunity to verify that your data have been used according to the law."
    }), "\n", _jsxs(_components.p, {
      children: ["Information about the requests of your credit information can be received free of charge twice a year at ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), ". There, you may see which legal entities and when (date and time) have checked your credit history."]
    }), "\n", _jsx(_components.p, {
      children: "Pursuant to the regulatory enactments effective in the Republic of Latvia, information about you may be requested only in case if there is a valid reasons for doing that, and in case you have authorized the information check. In case you have not authorized your credit history check, or in case the company which has checked your credit history has not signed an agreement with you on the provision of any services or sale of any commodities to you on deferred terms or on cash-on-delivery terms, you must notify the fact to JSC Kredītinformācijas Birojs (Credit Information Bureau). You may also lodge a complaint with the State Data Inspectorate against the respective legal entity (company) regarding unauthorised access to your credit history."
    }), "\n", _jsx(_components.h2, {
      children: "REPORTS"
    }), "\n", _jsxs(_components.p, {
      children: ["You may receive two different types of reports with information about you via ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), ".", _jsx(_components.br, {}), "\n", "THE FULL REPORT includes all information about your credit history from the members of the system operated by the Credit Information Bureau. The report contains following sections:"]
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["The Free report:", _jsx(_components.br, {}), "\n", "You are allowed to receive unlimited free reports; the report include information about your credit history provided by the members of the Credit Information Bureau. The report comprises the following sections:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "the current liabilities registered in your name;"
          }), "\n", _jsx(_components.li, {
            children: "the outstanding liabilities registered in your name;"
          }), "\n", _jsx(_components.li, {
            children: "the cleared debts registered in your name;"
          }), "\n", _jsx(_components.li, {
            children: "the log of queries for your data."
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["The annual subscription", _jsx(_components.br, {}), "\n", "In addition to the free reports, the annual subscription includes", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "the full interactive report;"
          }), "\n", _jsx(_components.li, {
            children: "information on your credit rating."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
