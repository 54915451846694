import GATSBY_COMPILED_MDX from "D:/BuildAgent02/work/3584ffa7ff58f781/libs/assets/blog/LVA/posts/article2/article2_en.md";
import React from 'react';
import {BlogArticleDetail, BlogHeroSection} from '@myci/module-blog';
import {PublicLayout} from '../layouts';
import {jsx as _jsx} from "react/jsx-runtime";
var ArticleDetail = function ArticleDetail(_ref) {
  var _pageContext$frontmat;
  var pageContext = _ref.pageContext, children = _ref.children;
  return _jsx(PublicLayout, {
    header: _jsx(BlogHeroSection, {
      backgroundData: pageContext === null || pageContext === void 0 ? void 0 : pageContext.hero,
      size: "small"
    }),
    children: _jsx("section", {
      className: "blog",
      children: _jsx("div", {
        className: "container",
        children: _jsx(BlogArticleDetail, {
          title: pageContext === null || pageContext === void 0 ? void 0 : (_pageContext$frontmat = pageContext.frontmatter) === null || _pageContext$frontmat === void 0 ? void 0 : _pageContext$frontmat.title,
          content: children
        })
      })
    })
  });
};
ArticleDetail
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ArticleDetail, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
